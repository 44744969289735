import React, { lazy, Suspense } from 'react';
import LocationInMap from '../Components/Map/LocationInMap';
import Contact from '../Components/Contact/Contact';
// import Department from '../Components/Department/Department';
import Apply from '../Components/Apply/Apply';
// import Appointment from '../Components/Appointment/Appointment';
import About from '../Components/About/About';
import Iconbox from '../Components/Iconbox/Iconbox';
import PostWrapper from '../Components/Post/PostWrapper';
import SpecialistsSlider from '../Components/Slider/SpecialistsSlider';
import TestimonialSlider from '../Components/Slider/TestimonialSlider';
import BrandSlider from '../Components/Slider/BrandSlider';
import Newsletter from '../Components/Newsletter/Newsletter';
import Accordion from '../Components/Accordion/Accordion';
import PriceSlider from '../Components/Slider/PriceSlider';
import Funfact from '../Components/Funfact/Funfact';
import BeforeAfter from '../Components/BeforeAfter/BeforeAfter';
import MasonryGallery from '../Components/Gallery/Gallery';
import Hero8 from '../Components/Hero/Hero8';
import Categories from '../Components/Categories/Categories';
import Preloader from '../Components/Preloader/Preloader';

const LazyHero = lazy(() => import("../Components/Hero/Hero8"));
const LazyIconbox = lazy(() => import("../Components/Iconbox/Iconbox"));
const LazyAbout = lazy(() => import("../Components/About/About"));
const LazyCategories = lazy(() => import("../Components/Categories/Categories"));
const LazyApply = lazy(() => import("../Components/Apply/Apply"));
const LazyAccordion = lazy(() => import("../Components/Accordion/Accordion"));
// const LazyHero = lazy(() => import("../Components/Hero/Hero8"));

const heroData = {
  bgImg: 'images/stars1.webp',
  bgShape: 'shape/hero-shape.png',
  videoSrc: 'https://www.youtube.com/watch?v=2MEy18SPIDM',
  sliderImages: [
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
  ],
  title: ['Cleaners', 'Mechanics', 'Make-Up Artists', 'Gardeners', 'Nail-Techs', 'Mama Fuas', 'Trainers', 'Tutors', 'Kinyozis', 'Plumbers', 'Painters', 'Chefs'],
};

const iconboxData = [
  {
    bg: 'purple',
    icon: 'icons/invitation.gif',
    title: 'Invite Only',
    subTitle:
      'We believe in high standards and to be able to offer your services, you need to be referred by someone already in TwoMbili',
  },
  {
    bg: 'green',
    icon: 'icons/payment-time.gif',
    title: 'Higher Rewards',
    subTitle:
      'Those who are the best should be paid better than the rest and rewarded for the extra work they put into their craft',
  },
  {
    bg: 'red',
    icon: 'icons/win-win.gif',
    title: 'Referral Commissions',
    subTitle:
      'When you refer someone, you will qualify for a cash bonus when they achieve milestones within the App',
  },
];

const aboutData = {
  title:
    'We look out for both Service Providers and Clients',
  subTitle:
    'Our job is to increase the level of trust between customers. We protect clients from conmen and protect Service Providers from unpaid invoices. <br /> We give the clients a wider variety of skilled professionals while offering Service Providers a place to showcase how good they are at what they do.',

  avater: {
    img: 'images/6.jpg',
    name: 'Fred',
    designation: 'CEO',
  },
  timeTable: [
    {
      day: 'Monday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Tuesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Wednesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Thursday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Friday',
      startTime: '9:00',
      endTime: '5:00',
    },
    {
      day: 'Saturday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Sunday',
      startTime: '9:00',
      endTime: '3:00',
    },
  ],
  contact: '(+01) - 234 567 890',
};

const faqData = {
  title: 'Need any Clarification?',
  img: 'images/faq-img.png',
  bgImg: 'shape/faq-bg.svg',
  faqItems: [
    {
      title: 'What is TwoMbili?',
      content: `TwoMbili is an App that connects Exceptional Service Providers to Customers who want quality services`,
    },
    {
      title: 'What benefits do I get by providing my services on TwoMbili?',
      content: `You get paid more than the current market price. We market you to clients who are willing to pay extra for quality work. Our Escrow system protects you from clients who don't pay. You get to prove your competence to new clients`,
    },
    {
      title: 'What benefits do I get from TwoMbili as a Customer?',
      content: `You get access to highly skilled and vetted Service Providers. Your money is protected for conmen. The Service Provider is only paid after the job is complete. You get to rate Service Providers`,
    },
    {
      title: 'How does payment of Services work on TwoMbili',
      content: `Clients deposit the estimate to TwoMbili account and we hold the money in Escrow. When the job is done, the client inspects and authorizes us to release the payment to the Service Provider`,
    },

  ],
};

const Home = () => {
  return (
    <>
     <Suspense fallback={<Preloader />}>
        <LazyHero data={heroData} />
        <LazyIconbox data={iconboxData} />
        <LazyAbout data={aboutData} />
        <LazyCategories />
        <LazyApply />
        <LazyAccordion data={faqData} />
      </Suspense>
      {/* <Hero8 data={heroData}/> */}
      {/* <Iconbox data={iconboxData} />
      <About data={aboutData} />
      <Categories />
      <Apply />
      <Accordion data={faqData} /> */}
      {/* <Department /> */}
      {/* <SpecialistsSlider data={specialistData} /> */}
      {/* <hr /> */}
      {/* <MasonryGallery /> */}
      {/* <BeforeAfter data={beforeAfterData} /> */}
      {/* <TestimonialSlider data={testimonialData} /> */}
      {/* <Funfact /> */}
      {/* <PriceSlider data={priceData} /> */}
      {/* <Newsletter data={newsletterData} /> */}
      {/* <PostWrapper data={postData} /> */}
      {/* <BrandSlider data={brandData} /> */}
      {/* <Contact /> */}
      {/* <LocationInMap data={mapLocationURL} /> */}
    </>
  );
};

export default Home;
