import React from 'react';

const Preloader = ({ varient }) => {
  return (
    <div className={`st-perloader ${varient ? varient : ""}`}>
      <div className="st-perloader-in">
        <div className="st-wave-first">

 <svg  version="1.0" xmlns="http://www.w3.org/2000/svg"  width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,512.000000) scale(0.050000,-0.050000)" fill="#462652" stroke="none"> <path d="M6453 8386 c-3 -2 -837 -7 -1854 -9 -2384 -6 -2412 -7 -2426 -63 -8 -33 -20 -1855 -14 -2109 2 -90 83 -25 703 561 189 178 457 428 595 556 l251 232 47 -56 c269 -320 1287 -319 1651 1 l74 66 175 -158 c95 -86 341 -310 545 -497 467 -427 639 -578 648 -568 4 4 10 467 15 1028 l7 1020 -206 1 c-113 0 -208 -2 -211 -5z"/> <path d="M4147 6644 c-147 -8 -140 26 -155 -834 -8 -429 -22 -1108 -32 -1510 -10 -401 -19 -914 -18 -1140 l0 -410 264 -314 c145 -172 276 -319 292 -325 40 -17 58 2 349 377 384 494 344 386 339 901 -3 243 -10 1040 -16 1771 -7 732 -17 1369 -22 1416 l-11 87 -443 -7 c-244 -3 -491 -9 -547 -12z"/> </g> </svg> 
          
        </div>
        <div className="st-wave-second">
        <svg  version="1.0" xmlns="http://www.w3.org/2000/svg"  width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,512.000000) scale(0.050000,-0.050000)" fill="#462652" stroke="none"> <path d="M6453 8386 c-3 -2 -837 -7 -1854 -9 -2384 -6 -2412 -7 -2426 -63 -8 -33 -20 -1855 -14 -2109 2 -90 83 -25 703 561 189 178 457 428 595 556 l251 232 47 -56 c269 -320 1287 -319 1651 1 l74 66 175 -158 c95 -86 341 -310 545 -497 467 -427 639 -578 648 -568 4 4 10 467 15 1028 l7 1020 -206 1 c-113 0 -208 -2 -211 -5z"/> <path d="M4147 6644 c-147 -8 -140 26 -155 -834 -8 -429 -22 -1108 -32 -1510 -10 -401 -19 -914 -18 -1140 l0 -410 264 -314 c145 -172 276 -319 292 -325 40 -17 58 2 349 377 384 494 344 386 339 901 -3 243 -10 1040 -16 1771 -7 732 -17 1369 -22 1416 l-11 87 -443 -7 c-244 -3 -491 -9 -547 -12z"/> </g> </svg> 
        </div>
      </div>
    </div>
  )
}

export default Preloader
