import React from 'react'
import { TypeAnimation } from 'react-type-animation';
// import { Icon } from '@iconify/react';
import { Link as ScrollLink } from 'react-scroll';

const Hero8 = ({ data }) => {
  const bgImg = data.bgImg;
  const titles = data.title;
  const newArray = titles.flatMap(element => [element, 3000]);

  return (
    <>
      <div className='st-height-b125 st-height-lg-b80 ' id='home'></div>
      <div style={{ backgroundColor: 'black' }}>
      <div
        id='home'
        className="st-hero-wrap st-gray-bg st-dynamic-bg overflow-hidden st-fixed-bg st-bg "
        style={{ backgroundImage: `url(${bgImg})`, backgroundColor: 'black'}}
      >
        {/* <div className="animated-icon-wrap">
          <div className="animated-icon">
            <Icon icon="fluent-emoji:woman-cook-dark" />
          </div>
          <div className="animated-icon">
          </div>
          <div className="animated-icon">
            <Icon icon="fluent-emoji:man-construction-worker-dark" />
          </div>
          <div className="animated-icon">
            <Icon icon="fluent-emoji:farmer-dark" />
          </div>
          <div className="animated-icon">
            <Icon icon="fluent-emoji:woman-getting-haircut-dark" />
          </div>
          <div className="animated-icon">
            <Icon icon="fluent-emoji:man-mechanic-dark" />
          </div>
          <div className="animated-icon">
            <Icon icon="fluent-emoji:nail-polish-dark" />
          </div>
        </div> */}
        <div className="st-hero st-style1 text-center">
          <div className="container">
            <div className="st-hero-text">
              <h1 className="st-hero-title text-white cd-headline clip">
                Only the Best:     <br /> 
                {/* pros like <span></span> */}
                 {/* <span><br /></span> */}
                <span className="cd-words-wrapper">
                  <TypeAnimation sequence={newArray}
                    speed={50}
                    repeat={Infinity} />
                </span>
              </h1>
              <div className="st-hero-subtitle text-white">
              <br /> Are you a star in your job? <br />  <br />
                Apply to stand a chance to join our Exclusive Team of Service Providers  <br /> who are paid more for being the best 
              </div>
              <div className="st-hero-btn">
                <ScrollLink to="apply" className="st-btn st-style1 st-color1 st-smooth-move" >
                  Apply Now
                </ScrollLink>
              </div>
              <div className="st-height-b10 st-height-lg-b10" />
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default Hero8
